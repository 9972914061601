import { Locale } from '../types'
import { navigate } from 'gatsby'
import { useWindowSize } from '../hooks/useWindowSize'
interface PageNavigationProps {
  enPath: string
  fiPath: string
  locale: Locale
  uri?: string
  returnPath?: boolean
}
export const switchNodeLocale = <T>(array: T[], locale: 'fi' | 'en'): T[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return array.filter(({ node }: any) => {
    return node.node_locale === locale
  })
}

export const handlePageNavigation = ({
  enPath,
  fiPath,
  uri,
  locale,
  returnPath = false,
}: PageNavigationProps) => {
  let url = ''
  if (locale === 'fi' && uri && !returnPath) {
    url = `/${fiPath}/${uri}`
    navigate(url)
  }
  if (locale === 'en' && uri && !returnPath) {
    url = `/en/${enPath}/${uri}`
    navigate(url)
  }
  if (locale === 'fi' && !uri && !returnPath) {
    url = `/${fiPath}`
    navigate(url)
  }
  if (locale === 'en' && !uri && !returnPath) {
    url = `/en/${enPath}`
    navigate(url)
  }

  if (locale === 'fi' && uri && returnPath) {
    url = `/${fiPath}/${uri}`
  }
  if (locale === 'en' && uri && returnPath) {
    url = `/en/${enPath}/${uri}`
  }
  if (locale === 'en' && !uri && returnPath) {
    url = `/en/${enPath}`
  }
  if (locale === 'fi' && !uri && returnPath) {
    url = `/${fiPath}`
  }

  return url
}

export const handleRemoveHyphen = (str: string, position = 0) => {
  const { width } = useWindowSize()

  if (width >= 510 && position) {
    // TODO: refactor this to regex
    const newStr = str.split(' ')
    newStr[position] = newStr[position].replace(/-/, '')
    return newStr.join(' ')
  }
  if (width >= 510) {
    return str.replace(/-/, '')
  } else {
    return str
  }
}

export const handleSplitTitle = (str: string) => {
  const hasBreak = str.includes('<br>')
  if (hasBreak) {
    return str.split('<br>')
  }
  return str.split('<br>')
}
export const handleSplit = (str: string) => {
  const hasBreak = str.includes('<br>')
  if (hasBreak) {
    return str.split('<br>')
  }
  return str.split('<br>')
}
