import React from 'react'
import classNames from 'classnames'
import { graphql, Link } from 'gatsby'

import SEO from '../../components/Seo'
import Header from '../../components/Header'
import { Locale, Project } from '../../types'
import { switchNodeLocale } from '../../utils/contentful'

import styles from './styles.module.css'
import Footer from '../../components/Footer';

type ProjectTemplateProps = {
  data: {
    projects: {
      edges: {
        node: Project
      }[]
    }
    content: {
      heroImageInProjectListPage: {
        title: string
        file: {
          url: string
        }
      }
    }
    metadata: {
      edges: {
        node: {
          node_locale: Locale
          page: string
          title: string
          description: string
        }
      }[]
    }
  }

  pageContext: {
    language: Locale
    intl: {
      messages: {}
    }
  }
  location: Location
}

const Projektit = ({
  data: { projects, content, metadata },
  pageContext: { language },
  location,
}: ProjectTemplateProps) => {
  const projectFiltered = switchNodeLocale(projects.edges, language)
  const [metadataFiltered] = switchNodeLocale(metadata.edges, language)
  return (
    <div className="w-screen overflow-x-hidden bg-primary">
      <Header isLandingPage={false} location={location} locale={language} />
      <SEO
        lang="fi"
        title={metadataFiltered.node.title}
        description={metadataFiltered.node.description}
      />
      <div className="max-w-screen-15inchLaptop mx-auto overflow-x-hidden pb-40 lg:pb-64">
        {/* <div
          className={styles.hero_backdrop}
          style={{
            backgroundImage: `url(${content.heroImageInProjectListPage.file.url})`,
          }}
        /> */}
        <div className="mx-auto w-10/12 lg:w-9/12 xl:w-8/12 mt-10 lg:mt-16">
          <h1 className="mb-8 lg:mb-12 uppercase text-5xl lg:text-6xl">
            {language === 'fi' ? 'projektit' : 'projects'}
          </h1>
          <div className="lg:w-full flex flex-wrap justify-between">
            {projectFiltered.map(({ node }) => (
              <Link
                key={node.title}
                to={node.customUri}
                className="flex flex-col items-end justify-self-center"
              >
                <img
                  src={node.portfolioPictures[0].file.url}
                  alt={node.portfolioPictures[0].title}
                  loading="lazy"
                  className={classNames(
                    'object-contain ml-auto my-4',
                    styles.portfolio_img,
                  )}
                />
                <h3 className="mt-4 lg:mt-6 uppercase text-3xl lg:text-4xl">
                  {node.title}
                </h3>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Footer locale={language} />
    </div>
  )
}

export default Projektit

export const ProjectQuery = graphql`
  query {
    projects: allContentfulProject {
      edges {
        node {
          id
          node_locale
          title
          housingType
          seoDescription
          customUri
          heroImage {
            file {
              url
            }
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              quality: 100
            )
          }
          portfolioPictures {
            file {
              url
            }
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              quality: 100
            )
          }
        }
      }
    }
    content: contentfulLandingPage {
      heroImageInProjectListPage {
        title
        file {
          url
        }
      }
    }
    metadata: allContentfulMetadata(filter: { page: { in: "Projects" } }) {
      edges {
        node {
          node_locale
          page
          title
          description
        }
      }
    }
  }
`
